import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import {Provider} from "react-redux";
import {store} from "./store/store";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

/* TODO credits
<a target="_blank" href="https://icons8.com/icon/XRg684bsHSsT/loading-circle">Loading Circle</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
 */

function renderApp(towerUrl: string) {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App towerUrl={towerUrl} />
      </Provider>
    </React.StrictMode>
  );
}

const defaultTowerUrl = 'http://localhost:8080/';

fetch('./index.json')
  .then(
    response => response.json(),
    error => {
      console.log('An error occurred.', error);
      return {};
    }
  )
  .then(
    (json) => renderApp(json.TOWER_URL || defaultTowerUrl),
    err => renderApp(defaultTowerUrl)
  )

