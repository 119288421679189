import {Node} from "reactflow";
import {useState} from "react";
import Modal from "react-modal";
import {ScriptEditor} from "../../script/editor/ScriptEditor";
import "./modal.css";
import {TNodeData} from "../../../../store/tower/transform";

export interface NodeEditorModalProps {
  node: Node<TNodeData<any>>;
  isOpen: boolean;
  onCancel: () => void;
  onSave: (node: Node) => void;
}

export default function NodeEditorModal({node, isOpen, onSave, onCancel}: NodeEditorModalProps) {
  const [updatedNode, setUpdatedNode] = useState(node);

  return <Modal
    isOpen={isOpen}
    contentLabel="Node editor"
    style={{overlay: {zIndex: 100}}}
  >
    <div className={'node-editor-modal-container'}>
      <div className={'header'}>
        <div className={'title'}>
          <label>Type: <input value={updatedNode.data.node.type}
                              onChange={event => setUpdatedNode({
                                ...updatedNode,
                                data: {
                                  ...updatedNode.data,
                                  node: {
                                    ...updatedNode.data.node,
                                    type: event.target.value
                                  }
                                }
                              })}/></label>
        </div>
      </div>
      <div className={'editor'}>
        <ScriptEditor language={'json'}
                      value={JSON.stringify(updatedNode.data.node.data, null, 2)}
                      onChange={newScript => {
                        try {
                          setUpdatedNode({
                            ...updatedNode,
                            data: {
                              ...updatedNode.data,
                              node: {
                                ...updatedNode.data.node,
                                data: JSON.parse(newScript)
                              }
                            }
                          })
                        } catch (e) {
                          console.log(e)
                        }
                      }} />
      </div>
      <div className={'actions'}>
        {onCancel && <button className={"cancel-button"} onClick={() => onCancel()}>cancel</button>}
        <button className={"save-button"} onClick={() => onSave(updatedNode)}>save</button>
      </div>
    </div>
  </Modal>;

}