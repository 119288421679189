import {Edge as RfEdge, MarkerType, Node as RfNode} from "reactflow";
import {TowerGraph} from "./store";
import {
  isLayoutNodeData,
  LayoutEdgeLabelDeprecated,
  LayoutEdgeLabel,
  LayoutNodeData,
  LayoutNodeType, CursorNodeType, CursorNodeData
} from "../../gatehouse-domain/data";
import {Node as TNode} from "./protocol";

export interface TNodeData<T = any> {
  node: TNode<T>;
}

export function tNodeDataWrap(data: any, id: string = "", type: string = ""): TNodeData {
  return {
    node: {
      id,
      type,
      data,
    }
  };
}

function makeRFNode(node: TNode, layoutData: LayoutNodeData): RfNode<TNodeData> {
  return {
    id: node.id,
    type: node.type,
    position: layoutData.position,
    width: (layoutData.dimensions && layoutData.dimensions.width) || null,
    height: (layoutData.dimensions && layoutData.dimensions.height) || null,
    data: {
      node,
    },
  }
}

export interface TransformResult {
  nodes: RfNode<TNodeData>[];
  edges: RfEdge[];
  missingLayout: string[];
  cursors: TNode[];
}

export function transformGraph(graph: TowerGraph): TransformResult {
  const nodes: RfNode[] = [];
  const edges: RfEdge[] = [];
  const missingLayout: string[] = [];

  // we need to extract layout information from the graph
  // and then transform it into a reactflow graph
  // layout is defined as a node with type "gatehouse-layout" which is connected to a node
  // that needs to be placed with edge of type "places"

  const tNodes = {...graph.nodes};
  const tEdges = {...graph.edges};

  const cursorNodes: TNode<CursorNodeData>[] = [];
  // take out cursors
  for (const nodeId in tNodes) {
    const node = tNodes[nodeId];
    if (node.type === CursorNodeType) {
      delete tNodes[nodeId];
      cursorNodes.push(node);
    }
  }

  for (const edgeId in graph.edges) {
    const edge = tEdges[edgeId];
    const sourceNode = tNodes[edge.source];
    const targetNode = tNodes[edge.target];

    if (edge.label === LayoutEdgeLabel && targetNode?.type === LayoutNodeType) {
      const layoutNode = targetNode;
      const node = tNodes[edge.source];
      const layoutData = layoutNode.data;
      if (!isLayoutNodeData(layoutData)) {
        console.warn(`Layout node ${layoutNode.id} has invalid layout data`, layoutData);
        continue;
      }
      nodes.push(makeRFNode(node, layoutData));
      // remove layout and the node from the future transformation
      delete tNodes[edge.source];
      delete tNodes[edge.target];
    } else if (edge.label === LayoutEdgeLabelDeprecated && sourceNode?.type === LayoutNodeType) {
      const layoutNode = sourceNode;
      const node = tNodes[edge.target];
      const layoutData = layoutNode.data;
      if (!isLayoutNodeData(layoutData)) {
        console.warn(`Layout node ${layoutNode.id} has invalid layout data`, layoutData);
        continue;
      }
      nodes.push(makeRFNode(node, layoutData));
      // remove layout and the node from the future transformation
      delete tNodes[edge.source];
      delete tNodes[edge.target];
    } else {
      // convert edge to reactflow edge as it is
      edges.push({
        id: edge.id,
        source: edge.source,
        sourceHandle: edge.data.sourceHandle || null,
        target: edge.target,
        targetHandle: edge.data.targetHandle || null,
        markerEnd: {
          type: MarkerType.ArrowClosed
        },
        type: 'smoothstep',
        style: {strokeWidth: 2},
        label: edge.label,
      })
    }
  }

  for (const nodeId in tNodes) {
    if (tNodes[nodeId].type === LayoutNodeType) {
      continue;
    }
    missingLayout.push(nodeId);
  }

  return {nodes, edges, missingLayout, cursors: cursorNodes};
}