import {Handle, Position, useStore, useUpdateNodeInternals} from "reactflow";
import {useEffect} from "react";
import "./style.css";
import {CustomNodeProps} from "../customNodeProps";
import {NodeResizeControl} from "@reactflow/node-resizer";

function toRFPosition(connPosition: ConnectionPosition) {
  switch (connPosition) {
    case "bottom":
      return Position.Bottom;
    case "top":
      return Position.Top;
    case "left":
      return Position.Left;
    case "right":
      return Position.Right;
  }

  //never reach
  return Position.Top
}

function handleStyle(i: number, total: number, position: ConnectionPosition) {
  const offset = `${100 * (i + 1) / (total + 1)}%`
  switch (position) {
    case "top":
    case "bottom":
      return {left: offset}
    case "right":
    case "left":
      return {top: offset}
  }
}


function makeHandle(id: string, position: ConnectionPosition, indexInRow: number, handlesInRowCount: number) {
  return <Handle
    key={id}
    type="source"
    id={id}
    position={toRFPosition(position)}
    className={'new-handle'}
    style={handleStyle(indexInRow, handlesInRowCount, toRFPosition(position))}
  />
}

function makeEasyHandle(id: string, isTarget: boolean) {
  return <Handle
    key={id} id={id}
    type="source"
    position={Position.Top}
    className={'new-handle easy' + (isTarget ? ' target' : '')}
  />
}


export type ConnectionPosition = 'top' | 'bottom' | 'left' | 'right';

export interface NodeHandle {
  id: string;
  position: ConnectionPosition;
}

function makeHandles(handles: NodeHandle[], isTarget: boolean) {
  const topHandleRow = handles.filter(h => h.position === 'top');
  const topHandleRowRendered = topHandleRow.map((handle, index) => {
    return makeHandle(handle.id, handle.position, index, topHandleRow.length);
  })

  const bottomHandleRow = handles.filter(h => h.position === 'bottom');
  const bottomHandleRowRendered = bottomHandleRow.map((handle, index) => {
    return makeHandle(handle.id, handle.position, index, bottomHandleRow.length);
  })

  const leftHandleRow = handles.filter(h => h.position === 'left');
  const leftHandleRowRendered = leftHandleRow.map((handle, index) => {
    return makeHandle(handle.id, handle.position, index, leftHandleRow.length);
  })

  const rightHandleRow = handles.filter(h => h.position === 'right');
  const rightHandleRowRendered = rightHandleRow.map((handle, index) => {
    return makeHandle(handle.id, handle.position, index, rightHandleRow.length);
  })

  return [
    ...topHandleRowRendered, ...bottomHandleRowRendered,
    ...leftHandleRowRendered, ...rightHandleRowRendered,
    makeEasyHandle('easy', isTarget)
  ]
}

type UnderlyingConstructor<Props> = (props: CustomNodeProps<Props>) => JSX.Element;


function makeHandleId(position: ConnectionPosition, number: number) {
  return `${position}-handle-${number}`
}

export const INITIAL_HANDLES: NodeHandle[] = [
  {id: makeHandleId('top', 0), position: 'top'},
  {id: makeHandleId('bottom', 0), position: 'bottom'},
  {id: makeHandleId('left', 0), position: 'left'},
  {id: makeHandleId('right', 0), position: 'right'},
]

export function MakeConnectable<Props, PropsConn extends Props>(underlying: UnderlyingConstructor<Props>) {

  function ConnectableNode(props: CustomNodeProps<PropsConn>) {
    const {id} = props;
    const updateNodeInternals = useUpdateNodeInternals();
    const isTarget = useStore(state => !!(state.connectionNodeId && state.connectionNodeId !== id));

    const nodeHandles = INITIAL_HANDLES

    useEffect(() => {
      updateNodeInternals(id);
    }, [id, nodeHandles, updateNodeInternals])

    return <div key={props.id}>
      {underlying(props)}
      {makeHandles(nodeHandles, isTarget)}
      <NodeResizeControl style={controlStyle} minWidth={100} minHeight={50}>
        <ResizeIcon/>
      </NodeResizeControl>
    </div>
  }

  return ConnectableNode;
}

const controlStyle = {
  background: 'transparent',
  border: 'none',
};

function ResizeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="#ff0071"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{position: 'absolute', right: 5, bottom: 5}}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <polyline points="16 20 20 20 20 16"/>
      <line x1="14" y1="14" x2="20" y2="20"/>
      <polyline points="8 4 4 4 4 8"/>
      <line x1="4" y1="4" x2="10" y2="10"/>
    </svg>
  );
}