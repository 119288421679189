import Modal from 'react-modal';
import {useState} from "react";
import './modal.css';
import {ScriptEditor} from "./ScriptEditor";
import {ScriptData} from "../node/ScriptNode";

export interface ScriptEditorModalProps {
  scriptData: ScriptData;
  scriptLibSource?: string;
  onSave: (newData: ScriptData) => void;
  onCancel?: () => void;
  isOpen: boolean;
  logs: string[];
  errors: string[];
}

export function ScriptEditorModal({isOpen, onSave, scriptData, onCancel, scriptLibSource, logs, errors}: ScriptEditorModalProps) {
  const [data, setData] = useState(scriptData);

  const [showOutput, setShowOutput] = useState(false);

  return <Modal
    isOpen={isOpen}
    contentLabel="Script editor"
    style={{
      overlay: {zIndex: 100},
      content: {
        padding: 0,
        maxWidth: '100%',
      }
    }}
  >
    <div className={'script-editor-modal-container'}>
      <div className={'header'}>
        <div
          className={"reactive-toggle" + (data.isReactive ? ' active' : '')}
          onClick={() => setData({...data, isReactive: !data.isReactive})}
        >⚡
        </div>
        <div className={'title'}>
          <input value={data.name} placeholder={"Script name"}
                 onChange={event => setData({...data, name: event.target.value})}/>
        </div>
        <button className={`output-button ${showOutput ? 'active' : ''}`}
                onClick={() => setShowOutput(prev => !prev)}>output
        </button>
      </div>
      <div className={"workbench"}>
        <div className={'editor'}>
          <ScriptEditor value={data.script} libSource={scriptLibSource}
                        onChange={newScript => setData({...data, script: newScript})}/>
        </div>
        {showOutput && <OutputArea logs={logs} errors={errors}/>}
      </div>
      <div className={'actions'}>
        {onCancel && <button className={"cancel-button"} onClick={() => onCancel()}>cancel</button>}
        <button className={"save-button"} onClick={() => onSave(data)}>save</button>
      </div>
    </div>
  </Modal>;
}

function OutputArea(props: { logs: string[], errors: string[] }) {
  const [showLogs, setShowLogs] = useState(true);
  const [showErrors, setShowErrors] = useState(true);

  const outputs = () => {
    let result: JSX.Element[] = [];

    if (showLogs) result = [...result, ...props.logs.map(line => <div>{line}</div>)]
    if (showErrors) result = [...result, ...props.errors.map(line => <div className={'error'}>{line}</div>)]

    return result;
  }

  return <div className={'output-area'}>
    <div className={'filters'}>
      <button className={`output-button ${showErrors ? 'active' : ''}`}
              onClick={() => setShowErrors(prev => !prev)}>errors
      </button>
      <button className={`output-button  ${showLogs ? 'active' : ''}`} onClick={() => setShowLogs(prev => !prev)}>logs
      </button>
    </div>
    <div className={'data'}>{outputs()}</div>
  </div>;
}