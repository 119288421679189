import {RootState} from "../store";
import {Connection} from "reactflow";
import {rfStateActions} from "./actions";
import {findLayoutNode} from "../tower/store";
import {towerActions} from "../tower/action";
import {ConnectionPosition} from "../../components/graph/connectable-trait/connectable";

function isConnectionPosition(value: string): value is ConnectionPosition {
  return ['top', 'bottom', 'left', 'right'].includes(value);
}

export function createEdge(state: RootState, connection: Connection, dispatch: (action: any) => void) {
  if (connection.source === null || connection.sourceHandle === null ||
    connection.target === null || connection.targetHandle === null) {
    return dispatch(rfStateActions.uiError('Cannot create edge with null source or target'));
  }
  // check if source and target are not the same
  if (connection.source === connection.target) {
    return dispatch(rfStateActions.uiError('Cannot connect a node to itself'));
  }

  if (connection.targetHandle === 'easy' || connection.targetHandle === null) {
    const sourceLayout = findLayoutNode(connection.source, state.graph);
    const targetLayout = findLayoutNode(connection.target, state.graph);
    if (!sourceLayout || !targetLayout) {
      return dispatch(rfStateActions.uiError('Cannot create edge with null source or target'));
    } else {
      const xDiff = sourceLayout.data.position.x - targetLayout.data.position.x;
      const yDiff = sourceLayout.data.position.y - targetLayout.data.position.y;
      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        connection = {...connection, targetHandle: xDiff < 0 ? 'left-handle-0' : 'right-handle-0'};
      } else {
        connection = {...connection, targetHandle: yDiff < 0 ? 'top-handle-0' : 'bottom-handle-0'};
      }
    }
  }

  const [sourcePosition, , rawSourceNumber] = (connection.sourceHandle || '').split('-')
  const [targetPosition, , rawTargetNumber] = connection.targetHandle!.split('-')

  const sourceNumber = parseInt(rawSourceNumber)
  const targetNumber = parseInt(rawTargetNumber)

  if (!isConnectionPosition(sourcePosition) || !isConnectionPosition(targetPosition)) {
    return dispatch(rfStateActions.uiError('Cannot create edge with invalid source or target handle position'));
  }
  if (isNaN(sourceNumber) || isNaN(targetNumber)) {
    return dispatch(rfStateActions.uiError('Cannot create edge with invalid source or target handle number'));
  }

  return dispatch(towerActions.createEdge({
    id: '',
    label: '',
    source: connection.source!,
    target: connection.target!,
    data: {
      sourceHandle: connection.sourceHandle,
      targetHandle: connection.targetHandle
    },
  }));
}