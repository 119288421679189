import {CustomNodeProps} from "../../customNodeProps";
import "./node.css";
import "../reactive-toggle.css"
import {TNodeData} from "../../../../store/tower/transform";

export interface ScriptData {
  language: 'javascript';
  name: string;
  script: string;
  isReactive?: boolean;
  lastRunMillis?: number; // unix timestamp
}

export interface ScriptNodeInteractiveProps {
  onPressRun: (id: string) => void;
}

export type ScriptNodeProps = CustomNodeProps<TNodeData<ScriptData>> & ScriptNodeInteractiveProps

export function ScriptNode({
                             id,
                             data: { node: { data: {name, lastRunMillis, isReactive}}},
                             onPressRun,
                           }: ScriptNodeProps) {

  const lastRun = lastRunMillis ? new Date(lastRunMillis).toLocaleString() : 'never';

  return <div className={'script-node'}>
    <div className={"header-row"}>
      <div className={"reactive-toggle" + (isReactive ? ' active' : '')}>⚡</div>
      <div className={"name"}>{name}</div>
      <div className={"actions"}>
        <button onClick={() => onPressRun(id)}
                onDoubleClick={(e) => e.stopPropagation()}
                >{lastRunMillis ? 'reRUN' : 'RUN'}</button>
      </div>
    </div>
    {lastRunMillis && <div className={"last-run"}>Last run: {lastRun}</div>}
  </div>
}