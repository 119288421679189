import {createAction} from "@reduxjs/toolkit";
import {XYPosition} from "reactflow";

export const dropzoneActions = {
  filesDropped: createAction<{files: File[], position: XYPosition}>('dropzone/filesDropped'),

  uploadStarted: createAction<{position: XYPosition, uploadId: string, filename: string}>('dropzone/uploadStarted'),
  uploadProgress: createAction<{uploadId: string, progress: number}>('dropzone/uploadProgress'),
  uploadFinished: createAction<{uploadId: string}>('dropzone/uploadFinished'),
  uploadFailed: createAction<{uploadId: string, error: Error}>('dropzone/uploadFailed'),

  setUrls: createAction<{baseUrl: string}>('dropzone/setUploadUrl'),
  dismissUpload: createAction<string>('dropzone/dismissUpload')
}

export interface Upload {
  position: XYPosition,
  filename: string,
  progress?: number,
  error?: Error,
  finished: boolean,
}

const dropzoneState = {
  uploadUrl: 'http://localhost:8080/upload' as string,
  blobUrl: 'http://localhost:8080/blob' as string,
  uploadsInProgress: {} as Record<string, Upload>,
}

export default function dropzoneReducer(state = dropzoneState, action: any): typeof dropzoneState {
  if (dropzoneActions.uploadStarted.match(action)) {
    const {position, filename, uploadId} = action.payload;
    return { ...state,
      uploadsInProgress: { ...state.uploadsInProgress,
        [uploadId]: {filename, position, finished: false}
      }
    }
  } else if (dropzoneActions.uploadProgress.match(action)) {
    const {uploadId, progress} = action.payload;
    return { ...state,
      uploadsInProgress: { ...state.uploadsInProgress,
        [uploadId]: { ...state.uploadsInProgress[uploadId],
          progress
        }
      }
    }
  } else if (dropzoneActions.uploadFailed.match(action)) {
    const {uploadId, error} = action.payload;
    return { ...state,
      uploadsInProgress: { ...state.uploadsInProgress,
        [uploadId]: { ...state.uploadsInProgress[uploadId],
          error
        }
      }
    };
  } else if (dropzoneActions.uploadFinished.match(action)) {
    const {uploadId} = action.payload;
    return { ...state,
      uploadsInProgress: { ...state.uploadsInProgress,
        [uploadId]: { ...state.uploadsInProgress[uploadId],
          finished: true
        }
      }
    };
  } else if (dropzoneActions.setUrls.match(action)) {
    const baseUrl = action.payload.baseUrl.replaceAll(/\/$/g, '');
    return {
      ...state,
      uploadUrl: `${baseUrl}/upload`,
      blobUrl: `${baseUrl}/blob`,
    }
  } else if (dropzoneActions.dismissUpload.match(action)) {
    const uploadId = action.payload;
    const {[uploadId]: _, ...updatedUploads} = state.uploadsInProgress;
    return {
      ...state,
      uploadsInProgress: updatedUploads
    };
  }
  return state;
}