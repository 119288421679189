import ReactFlow, {
  ConnectionMode,
  Controls, Edge, EdgeProps, EdgeTypes, getConnectedEdges,
  MiniMap,
  Node, NodeTypes, Panel,
  ReactFlowProvider, Transform, useReactFlow, useStoreApi, XYPosition
} from 'reactflow';
import {
  MouseEvent as ReactMouseEvent, useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {MarkdownData, MarkdownNode} from "./markdown/markdown-node/MarkdownNode";
import {MarkdownEditorModal} from "./markdown/markdown-editor/MarkdownEditorModal";
import {CommandNodeData, CommandPromptNode, makeCommandNodeInteractionProps} from "./command-prompt/CommandPromptNode";
import {DropEvent, FileRejection, useDropzone} from 'react-dropzone';
import {CustomNodeProps} from "./customNodeProps";
import {ScriptData, ScriptNode} from "./script/node/ScriptNode";
import './Graph.css';
import {ScriptEditorModal} from "./script/editor/ScriptEditorModal";
import {MakeConnectable} from "./connectable-trait/connectable";
import {TextNode, TextNodeData} from "./text/TextNode";
import {TextEditorModal} from "./text/TextNodeModal";
import 'reactflow/dist/style.css';
import {EditableEdge} from "./editable-edge/EditableEdge";
import {ChartNode} from "./chart/ChartNode";
import {PictureNode} from "./picture/PictureNode";
import {Node as TowerNode, Edge as TowerEdge} from "../../store/tower/protocol";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {TNodeData, transformGraph} from "../../store/tower/transform";
import {reactflowActions, rfStateActions} from "../../store/reactflow/actions";
import {dropzoneActions} from "../../store/dropzone/store";
import {towerActions} from "../../store/tower/action";
import NodeEditorModal from "./generic/editor/NodeEditorModal";
import {GenericNode} from "./generic/node/GenericNode";
import {IdentityPanel} from "./cursors/IdentityPanel";
import {CursorNodeData, LayoutEdgeLabel, LayoutNodeType} from "../../gatehouse-domain/data";
import {Cursor} from "./cursors/Cursor";
import {shallowEqual} from "react-redux";
import {UploadsStatus} from "../uploadsStatus/UploadsStatus";

interface MdNodeEditorState {
  node?: Node<TNodeData<MarkdownData>>;
}

interface ScriptNodeEditorState {
  node?: Node<TNodeData<ScriptData>>;
  logs?: string[];
  errors?: string[];
}

interface TextNodeEditorState {
  node?: Node<TNodeData<TextNodeData>>;
}

interface ContextMenuState {
  x: number;
  y: number;
}

const rendererPointToPoint = ({ x, y }: XYPosition, [tx, ty, tScale]: Transform): XYPosition => {
  return {
    x: x * tScale + tx,
    y: y * tScale + ty,
  };
};

function InternalGraph({towerUrl}: GraphProps) {
  console.log('RERENDER')
  const reactFlowRef = useRef<HTMLDivElement>(null);
  const {project} = useReactFlow();
  const {getState} = useStoreApi();

  const dispatch = useAppDispatch();
  const towerGraph = useAppSelector(state => ({
    nodes: state.graph.nodes,
    edges: state.graph.edges,
    version: state.graph.version,
  }), shallowEqual);
  const userCursor = useAppSelector(state => state.graph.cursor, shallowEqual);
  const connectionStatus = useAppSelector(state => state.graph.connectionStatus, shallowEqual);
  const scriptDeclarations = useAppSelector(state => state.graph.scriptDeclarations);
  const rflowState = useAppSelector(state => state.reactflow);
  const uploadsInProgress = useAppSelector(state => state.dropzone.uploadsInProgress);

  useEffect(() => {
    dispatch(dropzoneActions.setUrls({baseUrl: towerUrl}));
    dispatch(towerActions.connect(towerUrl))
    return () => {
      dispatch(towerActions.disconnect())
    }
  }, [towerUrl, dispatch]);

  const [reactNodes, setReactNodes] = useState<Node[]>([]);
  const [reactEdges, setReactEdges] = useState<Edge[]>([]);

  const [cursors, setCursors] = useState<TowerNode<CursorNodeData>[]>([]);

  useEffect(() => {
    console.log('GOT graph from tower', towerGraph)
    const {nodes, edges, missingLayout, cursors} = transformGraph(towerGraph);
    setCursors(cursors);
    console.log('Computed reactflow graph', nodes, edges)
    if (missingLayout.length > 0) {
      console.log('Missing layout for nodes', missingLayout);
      const nodesToCreate: TowerNode[] = [];
      const edgesToCreate: TowerEdge[] = [];
      missingLayout.forEach(nodeId => {
        const layoutNodeId = `gatehouse-layout-${nodeId}`;
        nodesToCreate.push({
          id: layoutNodeId,
          type: LayoutNodeType,
          data: {
            position: {
              x: 1000 + Math.random() * 500,
              y: 1000 + Math.random() * 500,
            },
          }
        });
        edgesToCreate.push({
          id: `layout-${nodeId}`,
          source: nodeId,
          target: layoutNodeId,
          label: LayoutEdgeLabel,
          data: {},
        });
      });
      dispatch(towerActions.createGraph({nodes: nodesToCreate, edges: edgesToCreate}));
    }
    setReactNodes(nodes);
    setReactEdges(edges);
  }, [towerGraph, dispatch]);

  const draggingNode = useAppSelector(state => state.reactflow.draggingNode);
  const selectedNode = useAppSelector(state => state.reactflow.selectedNode);

  useEffect(() => {
    const nodes = reactNodes.map(node => {
      if (node.id === draggingNode?.nodeId || node.id === selectedNode) {
        const position = draggingNode?.position;
        const dimensions = draggingNode?.dimensions;
        return {
          ...node,
          position: position || node.position,
          width: (dimensions && dimensions.width) || node.width,
          height: (dimensions && dimensions.height) || node.height,
          selected: selectedNode === node.id || node.selected,
        }
      }
      return node;
    });
    const edges = reactEdges;
    dispatch(rfStateActions.setGraph({nodes, edges}))
  }, [reactNodes, reactEdges, draggingNode, selectedNode, dispatch]);

  const [contextMenuState, setContextMenuState] = useState<ContextMenuState | undefined>();

  const [mdEditor, setMdEditor] = useState<MdNodeEditorState>({});
  const [scriptEditor, setScriptEditor] = useState<ScriptNodeEditorState>({});
  const [textEditor, setTextEditor] = useState<TextNodeEditorState>({});
  const [nodeEditor, setNodeEditor] = useState<Node<TNodeData> | undefined>();

  const onNodeDoubleClick = useCallback((event: ReactMouseEvent, node: Node) => {
    switch (node.type) {
      case 'markdown':
        setMdEditor({node: node});
        break;
      case 'script':
        const connectedEdges = getConnectedEdges([node], reactEdges)
        const logEdge = connectedEdges.find(e => e.label === 'log-output')
        const logNode = reactNodes.find(n => n.id === logEdge?.target)

        const errorEdge = connectedEdges.find(e => e.label === 'error-output')
        const errorNode = reactNodes.find(n => n.id === errorEdge?.target)

        const logs = logNode?.data.logs || []
        const errors = errorNode?.data.errors || []

        console.log('connectedEdges"', connectedEdges)

        setScriptEditor({node: node, logs, errors});
        break;
      case 'text':
        setTextEditor({node: node});
        break;
      default:
        setNodeEditor(node);
    }
  }, [setMdEditor, setScriptEditor, setTextEditor, reactNodes, reactEdges])

  const onCancelMarkdownEditor = useCallback(() => {
    setMdEditor({});
  }, [setMdEditor])

  const onSaveMarkdownEditor = useCallback((newContent: string) => {
    if (mdEditor.node) {
      dispatch(reactflowActions.updateNode({id: mdEditor.node!.id, newData: {content: newContent}}))
      setMdEditor({});
    } else {
      console.warn("WARN impossible state: onSaveMarkdownEditor called without node set")
    }
  }, [dispatch, setMdEditor, mdEditor])

  const onSaveScriptEditor = useCallback((newScriptData: ScriptData) => {
    if (scriptEditor.node) {
      dispatch(reactflowActions.updateNode({id: scriptEditor.node!.id, newData: newScriptData}));
      setScriptEditor({});
    } else {
      console.warn("WARN impossible state: onSaveScriptEditor called without node set")
    }
  }, [scriptEditor, setScriptEditor, dispatch])

  const onCancelScriptEditor = useCallback(() => {
    setScriptEditor({});
  }, [setScriptEditor])

  const onSaveTextEditor = useCallback((newGenericData: TextNodeData) => {
    if (textEditor.node) {
      dispatch(reactflowActions.updateNode({id: textEditor.node!.id, newData: newGenericData}));
      setTextEditor({});
    } else {
      console.warn("WARN impossible state: onSaveGenericEditor called without node set")
    }
  }, [dispatch, textEditor, setTextEditor])

  const onSaveNodeEditor = useCallback((node: Node<TNodeData>) => {
    if (!node.id) {
      dispatch(reactflowActions.createNode(node));
    } else {
      dispatch(reactflowActions.updateNode({id: node.id, newData: node.data.node.data, newType: node.data.node.type}));
    }
    setNodeEditor(undefined)
  }, [dispatch]);

  const onCancelNodeEditor = useCallback(() => {
    setNodeEditor(undefined)
  }, [setNodeEditor]);

  const onCancelTextEditor = useCallback(() => {
    setTextEditor({});
  }, [setTextEditor]);

  const getMousePosition = useCallback((event: any) => {
    if (!reactFlowRef.current) {
      console.error("reached drop callback before react-flow initialization", event)
      return;
    }
    if (!("clientX" in event) || !("clientY" in event)) {
      console.error("dropzone event does not have position data (clientX/clientY)", event)
      return;
    }
    const {top, left} = reactFlowRef.current.getBoundingClientRect();
    return project({x: event.clientX - left, y: event.clientY - top});
  }, [reactFlowRef, project]);

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
    const position = getMousePosition(event)
    if (!position) {
      return;
    }
    dispatch(dropzoneActions.filesDropped({files: acceptedFiles, position: position}));
  }, [dispatch, getMousePosition]);

  const onMouseMove = useCallback((event: ReactMouseEvent) => {
    const position = getMousePosition(event)
    if (!position) {
      return;
    }
    dispatch(reactflowActions.mouseMoved({position: position}));
  }, [dispatch, getMousePosition]);

  const {getRootProps: getDropzoneRootProps, getInputProps: getDropzoneFileInputProps} = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  })

  const [activePrompt, setActivePrompt] = useState<string | undefined>();

  const CommandPromptNodeInteractive = useCallback((props: CustomNodeProps<TNodeData<CommandNodeData>>) => {
    const interactionProps = makeCommandNodeInteractionProps(
      props,
      activePrompt,
      setActivePrompt,
      (nodeId: string, updateFunc: (data: any) => any) => {
        dispatch(reactflowActions.updateNode({id: nodeId, newData: null, modify: updateFunc}));
      }
    )
    return CommandPromptNode({...props, ...interactionProps})
  }, [activePrompt, dispatch])

  const ScriptNodeReflecting = useCallback((props: CustomNodeProps<TNodeData<ScriptData>>) => {
    return ScriptNode({
      ...props, onPressRun: () => {
        dispatch(towerActions.runScript(props.id));
      }
    })
  }, [dispatch]);

  const nodeTypes: NodeTypes = useMemo(() => {
    return {
      default: MakeConnectable(GenericNode),
      markdown: MakeConnectable(MarkdownNode),
      commandPrompt: MakeConnectable(CommandPromptNodeInteractive),
      picture: MakeConnectable(PictureNode),
      script: MakeConnectable(ScriptNodeReflecting),
      text: MakeConnectable(TextNode),
      chart: MakeConnectable(ChartNode),
    }
  }, [CommandPromptNodeInteractive, ScriptNodeReflecting])

  const [edgeEdited, setEdgeEdited] = useState<string | undefined>();

  const EditableEdgeInteractive = useCallback((props: EdgeProps) => {
    const onLabelUpdate = (id: string, newLabel: string) => {
      dispatch(reactflowActions.edgeLabelUpdate({id: props.id, newLabel}));
      setEdgeEdited(undefined);
    }
    const onLabelStartEditing = () => {
      setEdgeEdited(props.id);
    }
    const inEditMode = props.id === edgeEdited;
    return EditableEdge({...props, onLabelStartEditing, onLabelUpdate, inEditMode})
  }, [edgeEdited, dispatch]);

  const edgeTypes: EdgeTypes = useMemo(() => {
    return {
      default: EditableEdgeInteractive,
      'smoothstep': EditableEdgeInteractive,
    }
  }, [EditableEdgeInteractive]);

  const contextMenuItems = useMemo(() => ({
    'Markdown node': () => {
      if (!contextMenuState) return;
      dispatch(reactflowActions.createMarkdownNode(project({x: contextMenuState.x, y: contextMenuState.y})));
    },
    'Script node': () => {
      if (!contextMenuState) return;
      dispatch(reactflowActions.createNode({
        type: 'script',
        data: {
          node: {
            id: "",
            type: 'script',
            data: {
              language: 'javascript',
              name: 'untitled script',
              script: '',
              isReactive: false,
            }
          }
        },
        position: project({x: contextMenuState.x, y: contextMenuState.y})
      }));
    },
    'Text node': () => {
      if (!contextMenuState) return;
      dispatch(reactflowActions.createTextNode(project({x: contextMenuState.x, y: contextMenuState.y})));
    },
    'Chart node': () => {
      if (!contextMenuState) return;
      dispatch(reactflowActions.createChartNode(project({x: contextMenuState.x, y: contextMenuState.y})));
    },
    'Proxy node': () => {
      if (!contextMenuState) return;
      dispatch(reactflowActions.createProxyNode(project({x: contextMenuState.x, y: contextMenuState.y})));
    },
    'Raw node': () => {
      if (!contextMenuState) return;
      setNodeEditor({
        id: '',
        type: '',
        data: {
          node: {
            id: '',
            type: '',
            data: {},
          }
        },
        position: project({x: contextMenuState.x, y: contextMenuState.y}),
      })
    }
  }), [project, contextMenuState, dispatch])

  const cancelInteractions = useCallback(() => {
    setEdgeEdited(undefined);
    setContextMenuState(undefined);
  }, []);

  return (
    <>
      <ReactFlow
        {...getDropzoneRootProps()}
        ref={reactFlowRef}
        nodes={rflowState.nodes}
        edges={rflowState.edges}
        onNodesChange={changes => dispatch(reactflowActions.nodeChanges(changes))}
        onEdgesChange={changes => dispatch(reactflowActions.edgeChanges(changes))}
        onConnect={connection => dispatch(reactflowActions.connect(connection))}
        onEdgeUpdateStart={() => dispatch(reactflowActions.edgeUpdateStart())}
        onEdgeUpdate={(oldEdge, newConnection) => dispatch(reactflowActions.edgeUpdate({oldEdge, newConnection}))}
        onEdgeUpdateEnd={(mouseEvent, edge, handleType) => dispatch(reactflowActions.edgeUpdateEnd({
          mouseEvent,
          edge,
          handleType
        }))}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView={true}
        onNodeDoubleClick={onNodeDoubleClick}
        onClick={() => cancelInteractions()}
        onDoubleClickCapture={() => cancelInteractions()}
        onContextMenu={event => {
          if (reactFlowRef.current && event.target instanceof Element) {
            const targetIsPane = event.target.classList.contains('react-flow__pane');
            if (targetIsPane) {
              console.log(event)
              console.log({x: event.clientX, y: event.clientY});
              let xxx = project({x: event.clientX, y: event.clientY})
              console.log(xxx);
              console.log(rendererPointToPoint(xxx, getState().transform))
              setContextMenuState({x: event.clientX, y: event.clientY})
            }
          }
          event.preventDefault();
        }}
        deleteKeyCode={['Delete', 'Backspace']}
        connectionMode={ConnectionMode.Loose}
        onMouseMove={onMouseMove}
      >
        {
          cursors
            .filter(c => c.data.position)
            .map((cursor) => {
              console.log("mouse received", cursor.data.position!);
              const {x, y} = rendererPointToPoint(cursor.data.position!, getState().transform);
              return <div style={{
                  position: 'absolute',
                  top: y+'px',
                  left: x+'px',
                }}>
                  <Cursor key={cursor.id}
                          baseColor={cursor.data.color || '#000000'}
                          name={cursor.data.name || 'unknown'}
                  />
              </div>
            })
        }
        {contextMenuState && (
          <div className={'context-menu'} style={{
            position: 'absolute',
            top: contextMenuState.y,
            left: contextMenuState.x,
          }}>
            {Object.entries(contextMenuItems).map(([label, onClick]) => (
              <div className={'item'} key={label} onClick={onClick}>
                {label}
              </div>
            ))}
          </div>
        )}
        <MiniMap/>
        <Controls/>
        <Panel position="top-right">
          <IdentityPanel identity={userCursor.identity} />
        </Panel>
        <Panel position="bottom-center">
          {
            (Object.keys(uploadsInProgress).length > 0) ? (
              <UploadsStatus
                uploads={uploadsInProgress}
                onDismiss={(key) => dispatch(dropzoneActions.dismissUpload(key))} />
            ) : null
          }
          {connectionStatus.type}
        </Panel>
        <input {...getDropzoneFileInputProps()}/>
      </ReactFlow>
      {
        mdEditor.node && <MarkdownEditorModal originalText={mdEditor.node?.data.node.data.content || ''}
                                              onSave={onSaveMarkdownEditor} onCancel={onCancelMarkdownEditor}
                                              isOpen={true}/>
      }
      {
        scriptEditor.node && <ScriptEditorModal scriptData={scriptEditor.node.data.node.data}
                                                scriptLibSource={scriptDeclarations}
                                                onSave={onSaveScriptEditor} onCancel={onCancelScriptEditor}
                                                isOpen={true}
                                                logs={scriptEditor.logs || []}
                                                errors={scriptEditor.errors || []}
          />
      }
      {
        textEditor.node && <TextEditorModal textData={textEditor.node.data.node.data}
                                            onSave={onSaveTextEditor} onCancel={onCancelTextEditor}
                                            isOpen={true}/>
      }
      {
        nodeEditor && <NodeEditorModal node={nodeEditor} isOpen={true}
                                       onSave={onSaveNodeEditor}
                                       onCancel={onCancelNodeEditor}/>
      }
    </>
  );
}

export interface GraphProps {
  towerUrl: string
}

export function Graph({towerUrl}: GraphProps) {
  return <ReactFlowProvider>
    <InternalGraph towerUrl={towerUrl}/>
  </ReactFlowProvider>
}
