import './style.css';
import * as React from "react";
import {CustomNodeProps} from "../customNodeProps";
import {TNodeData} from "../../../store/tower/transform";

export interface PictureData {
  picture_url: string;
  description?: string;
}

export type PictureNodeProps = CustomNodeProps<TNodeData<PictureData>>

export function PictureNode(props: PictureNodeProps) {
  const {
    data: {
      node: {
        data: {
          picture_url,
          description,
        }
      }
    },
  } = props

  return (
    <div className={'picture-node-container nowheel'}>
      <img alt={description} src={picture_url} width={'100%'}/>
    </div>
  );
}