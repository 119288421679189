import Modal from 'react-modal';
import {useState} from "react";
import './modal.css';
import {TextNodeData} from "./TextNode";
import Editor from "@monaco-editor/react";

export interface TextEditorModalProps {
  textData: TextNodeData;
  onSave: (newData: TextNodeData) => void;
  onCancel?: () => void;
  isOpen: boolean;
}

export function TextEditorModal({isOpen, onSave, textData, onCancel}: TextEditorModalProps) {
  const [data, setData] = useState(textData.content ?? "");

  return <Modal
    isOpen={isOpen}
    contentLabel="Text editor modal"
    style={{overlay: {zIndex: 100}}}
  >
    <div className={'text-editor-modal-container'}>
      <div className={'editor'}>
        <Editor
          height="100%"
          defaultLanguage="text"
          defaultValue={data}
          onChange={(newValue) => {
            if (newValue || newValue === '') setData(newValue)
          }}
        />
      </div>
      <div className={'actions'}>
        {onCancel && <button className={"cancel-button"} onClick={() => onCancel()}>cancel</button>}
        <button className={"save-button"} onClick={() => {
          onSave({content: data})
        }}>save
        </button>
      </div>
    </div>
  </Modal>;
}