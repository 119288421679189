import {combineReducers, configureStore} from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import towerGraphReducer from "./tower/store";
import {towerMiddleware} from "./tower/middleware";
import reactflowReducer from "./reactflow/store";
import {reactflowMiddleware} from "./reactflow/middleware";
import dropzoneReducer from "./dropzone/store";
import {dropzoneMiddleware} from "./dropzone/middleware";


const rootReducer = combineReducers({
  graph: towerGraphReducer,
  reactflow: reactflowReducer,
  dropzone: dropzoneReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(towerMiddleware)
      .prepend(reactflowMiddleware)
      .prepend(dropzoneMiddleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector