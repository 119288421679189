
export const LayoutNodeType = 'gatehouse-layout';
export const LayoutEdgeLabelDeprecated = 'places';
export const LayoutEdgeLabel = 'placed-with'; // [a node] is [placed-with] [a layout node]
export const CursorNodeType = 'gatehouse-cursor';

export interface LayoutNodeData {
  position: {x: number, y: number}
  dimensions?: {width: number, height: number}
}

export interface CursorNodeData {
  name?: string
  color?: string
  position?: {x: number, y: number}
}

export function isLayoutNodeData(data: any): data is LayoutNodeData {
  return data && data.position && data.position.x && data.position.y &&
    (data.dimensions === undefined || (data.dimensions && data.dimensions.width && data.dimensions.height));
}
