import {createAction} from "@reduxjs/toolkit";
import {Edge, Node, Snapshot, SnapshotUpdate} from "./protocol";
import {ConnectionStatus} from "./store";

export const towerActions = {
  connect: createAction<string>('tower/connectToTower'),
  disconnect: createAction('tower/disconnectFromTower'),
  connectionEstablished: createAction('tower/connectionEstablished'),
  connectionClosed: createAction<{ code: number, reason: string, wasClean: boolean }>('tower/connectionClosed'),

  connectionStatus: createAction<ConnectionStatus>('tower/connectionStatus'),

  getScriptDeclarations: createAction('tower/getScriptDeclarations'),
  setScriptDeclarations: createAction<string>('tower/setScriptDeclarations'),

  setSnapshot: createAction<Snapshot>('tower/setSnapshot'),
  snapshotUpdate: createAction<SnapshotUpdate>('tower/snapshotUpdate'),
  snapshotHasBeenUpdated: createAction('tower/snapshotHasBeenUpdated'),

  createGraph: createAction<{nodes: Node[], edges: Edge[]}>('tower/createGraph'),

  createNode: createAction<Node>('tower/createNode'),
  updateNode: createAction<Node>('tower/updateNode'),
  deleteNode: createAction<string>('tower/deleteNode'),

  createEdge: createAction<Edge>('tower/createEdge'),
  updateEdge: createAction<Edge>('tower/updateEdge'),
  deleteEdge: createAction<string>('tower/deleteEdge'),

  runScript: createAction<string>('tower/runScript'),

  setCursorNodeId: createAction<string>('tower/setCursorNodeId'),
};