import {Upload} from "../../store/dropzone/store";
import "./styles.css";

interface Props {
  uploads: Record<string, Upload>
  onDismiss?: (id: string) => void
}

export function UploadsStatus({uploads, onDismiss}: Props) {
  return (
    <div className={"uploads-status"}>
      {Object.keys(uploads).map((id) => {
        const upload = uploads[id];
        return <div key={id} className={"upload"}>
          {
            (upload.error && (
              <>
                <span className={"error icon"}/>
                <div className={"filename"}>{upload.filename}</div>
                <div className={"dismiss button"} onClick={_ => onDismiss && onDismiss(id)}></div>
                <div className={"error"}>Error: {upload.error.message}</div>
              </>
            )) || (upload.finished && (
              <>
                <span className={"success icon"}/>
                <div className={"filename"}>{upload.filename}</div>
              </>
            )) || (
              <>
                <span className={"loading icon"}/>
                <div className={"filename"}>{upload.filename}</div>
                {upload.progress !== undefined && <div className={"progress"}>{upload.progress}%</div>}
              </>
            )
          }
        </div>
      })}
    </div>
  );
}