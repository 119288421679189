import {Cursor} from "./Cursor";
import "./styles.css";
import {useState} from "react";
import {generateColor} from "./generators";

export interface Identity {
  name: string;
  color: string;
}

export interface IdentityPanelProps {
  identity: Identity;
  onChange?: (identity: Identity) => void;
}

export function IdentityPanel({identity, onChange}: IdentityPanelProps) {
  const [isEditingName, setIsEditingName] = useState(false);
  const [name, setName] = useState(identity.name);

  const onCursorClick = () => {
    onChange && onChange({...identity, color: generateColor()});
  }

  return <div className={"identity-panel"}>
    <Cursor baseColor={identity.color} onClick={onCursorClick}/>
    <div className={"greeting"}>
      {(isEditingName) ? (
        <input type="text" value={name}
               onChange={(e) => setName(e.target.value)}
               onKeyUp={(e) => {
                 if (e.key === 'Enter') {
                   onChange && onChange({...identity, name});
                   setIsEditingName(false);
                 } else if (e.key === 'Escape') {
                   setIsEditingName(false);
                   setName(identity.name);
                 }
               }}
               autoFocus={true}
        />
      ) : (<>
        You are <a href="/" className={"name"}
                   onClick={(e) => {setIsEditingName(true); e.preventDefault()}}
                    >{identity.name || '<empty>' }</a>
      </>)}
    </div>
  </div>
}

