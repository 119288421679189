import {Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {reactflowActions} from "../reactflow/actions";
import {dropzoneActions} from "./store";
import {AppDispatch, RootState} from "../store";


export const dropzoneMiddleware: Middleware = (store: MiddlewareAPI<AppDispatch, RootState>) => next => action => {
  if (dropzoneActions.filesDropped.match(action)) {
    const {files, position} = action.payload;
    files.forEach((file, i) => {
      const filename = file.name;
      const uploadId = (Math.random() * 10000000).toString(36);
      store.dispatch(dropzoneActions.uploadStarted({position, uploadId, filename}));

      const formData = new FormData();
      formData.append('file', file, file.name);

      fetch(store.getState().dropzone.uploadUrl, {
        method: 'POST',
        body: formData,
      }).then(r => r.json()).then(response => {
        console.log("Upload response", response);
        const result = response as Record<string, string>;
        const blobId = result[file.name];
        console.log(blobId);
        store.dispatch(dropzoneActions.uploadFinished({uploadId}));
        setTimeout(() => {
          store.dispatch(dropzoneActions.dismissUpload(uploadId));
        }, 5000);
        store.dispatch(reactflowActions.createNode({
          type: 'picture',
          position: {x: position.x + i * 10, y: position.y - i * 10},
          data: {
            node: {
              id: "",
              type: "picture",
              data: {
                picture_url: store.getState().dropzone.blobUrl + '/' + blobId,
              }
            }
          }
        }));
      }).catch(error => {
        store.dispatch(dropzoneActions.uploadFailed({uploadId, error}));
        setTimeout(() => {
          store.dispatch(dropzoneActions.dismissUpload(uploadId));
        }, 5000);
      });
    })
  }
  return next(action);
}
