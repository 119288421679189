import {createAction} from "@reduxjs/toolkit";
import {Connection, Node, Edge, EdgeChange, HandleType, NodeChange, XYPosition} from "reactflow";
import {
  EdgeSelectionChange,
  NodeDimensionChange,
  NodePositionChange,
  NodeSelectionChange
} from "@reactflow/core/dist/esm/types/changes";
import {TNodeData, tNodeDataWrap} from "../tower/transform";

export const reactflowActions = {
  nodeChanges: createAction<NodeChange[]>('reactflow/nodeChanges'),
  edgeChanges: createAction<EdgeChange[]>('reactflow/edgeChanges'),
  connect: createAction<Connection>('reactflow/connect'),
  edgeUpdateStart: createAction('reactflow/edgeUpdateStart'),
  edgeUpdate: createAction<{oldEdge: Edge, newConnection: Connection}>('reactflow/edgeUpdate'),
  edgeUpdateEnd: createAction<{mouseEvent: MouseEvent, edge: Edge, handleType: HandleType}>('reactflow/edgeUpdateEnd'),

  createNode: createAction<{type?: string, data: TNodeData, position: XYPosition}>('reactflow/createNode'),
  updateNode: createAction<{id: string, newData: any, modify?: (oldData: any) => any, newType?: string}>('reactflow/updateNode'),
  edgeLabelUpdate: createAction<{id: string, newLabel: string}>('reactflow/edgeLabelUpdate'),

  mouseMoved: createAction<{ position: XYPosition }>('reactflow/mouseMoved'),

  createMarkdownNode(position: XYPosition) {
    return this.createNode({type: 'markdown', data: tNodeDataWrap({content: '__change me__'}), position})
  },
  createTextNode(position: XYPosition) {
    return this.createNode({type: 'text', data: tNodeDataWrap({}), position})
  },
  createProxyNode(position: XYPosition) {
    return this.createNode({type: 'proxy', data: tNodeDataWrap({}), position})
  },
  createChartNode(position: XYPosition) {
    return this.createNode({type: 'chart', data: tNodeDataWrap({}), position})
  },
}

export const rfStateActions = {
  draggingNode: createAction<NodeDimensionChange | NodePositionChange>('rfState/draggingNode'),
  nodeSelection: createAction<NodeSelectionChange>('rfState/nodeSelection'),
  edgeSelection: createAction<EdgeSelectionChange>('rfState/edgeSelection'),
  uiError: createAction<string>('rfState/uiError'),
  setGraph: createAction<{nodes: Node[], edges: Edge[]}>('rfState/setGraph'),
}
