import React from 'react';
import {Graph} from "../components/graph/Graph";
import "./App.css";

function App(props: {towerUrl: string}) {
  return (
    <Graph towerUrl={props.towerUrl}></Graph>
  );
}

export default App;
