import chroma from "chroma-js";
import {Identity} from "./IdentityPanel";

const goby = require('goby').init({
  decorator: (pieces: string[]) => pieces.map(capitalize).join(' ')
});

function capitalize(s: string) {
  return s[0].toUpperCase() + s.slice(1);
}

export function generateName() {
  return goby.generate(['adj', 'suf']);
}

export function generateColor() {
  return chroma.hsl(Math.random() * 360, 0.7, 0.6).hex()
}

export function generateIdentity(): Identity {
  return {
    name: generateName(),
    color: generateColor(),
  }
}
