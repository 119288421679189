import {Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {Connection, NodeChange} from "reactflow";
import {findLayoutNode} from "../tower/store";
import {reactflowActions, rfStateActions} from "./actions";
import {LayoutEdgeLabel, LayoutNodeType} from "../../gatehouse-domain/data";
import {createEdge} from "./edge-utils";
import {towerActions} from "../tower/action";
import {AppDispatch, RootState} from "../store";

export const reactflowMiddleware: Middleware = (store: MiddlewareAPI<AppDispatch, RootState>) => {
  let draggingEdgeNow = false;

  return next => action => {
    if (reactflowActions.nodeChanges.match(action)) {
      const nodeChanges = action.payload;
      nodeChanges.forEach((change: NodeChange) => {
        switch (change.type) {
          case "dimensions": {
            store.dispatch(rfStateActions.draggingNode(change));
            const layoutNode = findLayoutNode(change.id, store.getState().graph);
            if (layoutNode && change.dimensions) {
              if (change.dimensions.width === layoutNode.data.dimensions?.width && change.dimensions.height === layoutNode.data.dimensions?.height) {
                break;
              }
              store.dispatch(towerActions.updateNode({
                ...layoutNode,
                data: {
                  ...layoutNode.data,
                  dimensions: {
                    width: change.dimensions.width,
                    height: change.dimensions.height,
                  }
                }
              }));
            }
            break;
          }
          case "position": {
            store.dispatch(rfStateActions.draggingNode(change));
            const layoutNode = findLayoutNode(change.id, store.getState().graph);
            console.log('layout', layoutNode, change);
            if (layoutNode && change.position?.y && change.position.x) {
              store.dispatch(towerActions.updateNode({
                ...layoutNode,
                data: {
                  ...layoutNode.data,
                  position: {
                    x: change.position?.x,
                    y: change.position?.y,
                  }
                }
              }));
            }
            break;
          }
          case "select":
            store.dispatch(rfStateActions.nodeSelection(change));
            break;
          case "add":
            console.warn('unhandled change', change);
            break;
          case "remove":
            store.dispatch(towerActions.deleteNode(change.id));
            const layoutNode = findLayoutNode(change.id, store.getState().graph)
            if (layoutNode) {
              store.dispatch(towerActions.deleteNode(layoutNode.id));
            }
            break;
          case "reset":
            console.warn('unhandled change', change);
            break;
        }
      });
    } else if (reactflowActions.edgeChanges.match(action)) {
      action.payload.forEach(edgeChange => {
        switch (edgeChange.type) {
          case "add":
            console.warn('unhandled edge add', action.payload);
            break;
          case "select":
            store.dispatch(rfStateActions.edgeSelection(edgeChange));
            break;
          case "remove":
            store.dispatch(towerActions.deleteEdge(edgeChange.id));
            break;
          case "reset":
            console.warn('unhandled edge reset', action.payload);
            break;
        }
      });
    } else if (reactflowActions.connect.match(action)) {
      createEdge(store.getState(), action.payload as Connection, store.dispatch);
    } else if (reactflowActions.edgeUpdateStart.match(action)) {
      draggingEdgeNow = true;
    } else if (reactflowActions.edgeUpdate.match(action)) {
      draggingEdgeNow = false;
      const {oldEdge, newConnection} = action.payload;
      const edge = store.getState().graph.edges[oldEdge.id]
      store.dispatch(towerActions.updateEdge({
        ...edge,
        source: newConnection.source || oldEdge.source,
        target: newConnection.target || oldEdge.target,
        data: {
          ...edge.data,
          sourceHandle: newConnection.sourceHandle || oldEdge.data.sourceHandle,
          targetHandle: newConnection.targetHandle || oldEdge.data.targetHandle,
        }
      }));
    } else if (reactflowActions.edgeUpdateEnd.match(action)) {
      if (draggingEdgeNow) {
        store.dispatch(towerActions.deleteEdge(action.payload.edge.id));
        draggingEdgeNow = false;
      }
    } else if (reactflowActions.createNode.match(action)) {
      const {type, data, position} = action.payload;
      store.dispatch(towerActions.createGraph({
        nodes: [
          {id: '$node', type: data.node.type || type || '', data: data.node.data},
          {id: '$layout', type: LayoutNodeType, data: {position}}
        ],
        edges: [
          {id: '', label: LayoutEdgeLabel, source: '$node', target: '$layout', data: {}}
        ],
      }));
    } else if (reactflowActions.updateNode.match(action)) {
      const {id, newData, modify} = action.payload;
      const oldNode = store.getState().graph.nodes[id];
      const data = modify ? modify(oldNode.data) : newData;
      const type = action.payload.newType || oldNode.type;
      store.dispatch(towerActions.updateNode({...oldNode, type, data}));
    } else if (reactflowActions.edgeLabelUpdate.match(action)) {
      const {id, newLabel} = action.payload;
      const edge = store.getState().graph.edges[id];
      store.dispatch(towerActions.updateEdge({...edge, label: newLabel}));
    } else {
      next(action);
    }
  }
}
