export interface ErrorResponse {
  id: string;
  error: string;
}

export interface SuccessResponse {
  id: string;
  result: any | ServerMessage;
}

export type Response = ErrorResponse | SuccessResponse;

export function isSuccess(response: Response): response is SuccessResponse {
  return (response as ErrorResponse).error === undefined;
}

export function isError(response: Response): response is ErrorResponse {
  return !isSuccess(response);
}


export interface Node<T = any> {
  id: string;
  type: string;
  data: T;
}

export interface Edge {
  id: string;
  label: string;
  source: string;
  target: string;
  data: any;
}

export interface Graph {
  nodes: Node[];
  edges: Edge[];
}

export interface Snapshot {
  version: number;
  graph: Graph;
}

export interface NodeUpdated {
  node: Node;
}

export interface EdgeUpdated {
  edge: Edge;
}

export interface NodeDeleted {
  id: string;
}

export interface EdgeDeleted {
  id: string;
}

export type Update =
  {NodeUpdated: NodeUpdated}
  | {EdgeUpdated: EdgeUpdated}
  | {NodeDeleted: NodeDeleted}
  | {EdgeDeleted: EdgeDeleted};

export function isNodeUpdated(update: Update): update is { NodeUpdated: NodeUpdated } {
  return (update as { NodeUpdated: NodeUpdated }).NodeUpdated !== undefined;
}

export function isEdgeUpdated(update: Update): update is { EdgeUpdated: EdgeUpdated } {
  return (update as { EdgeUpdated: EdgeUpdated }).EdgeUpdated !== undefined;
}

export function isNodeDeleted(update: Update): update is { NodeDeleted: NodeDeleted } {
  return (update as { NodeDeleted: NodeDeleted }).NodeDeleted !== undefined;
}

export function isEdgeDeleted(update: Update): update is { EdgeDeleted: EdgeDeleted } {
  return (update as { EdgeDeleted: EdgeDeleted }).EdgeDeleted !== undefined;
}

export interface SnapshotUpdate {
  version: number;
  updates: Update[];
}

type ServerMessage = {Snapshot: Snapshot} | {SnapshotUpdate: SnapshotUpdate};

export function isSnapshot(result: ServerMessage): result is { Snapshot: Snapshot } {
  return (result as { Snapshot: Snapshot }).Snapshot !== undefined;
}

export function isSnapshotUpdate(result: ServerMessage): result is { SnapshotUpdate: SnapshotUpdate } {
  return (result as { SnapshotUpdate: SnapshotUpdate }).SnapshotUpdate !== undefined;
}

export const TowerProtocol = {
  createRequestId: function () {
    return Math.floor(Math.random() * 1000000000).toString(16);
  },
  getSnapshot: function (subscribe: boolean) {
    return {"GetSnapshot":{"id": this.createRequestId(), "subscribe":true}};
  },
  stopSubscription: function (id: string) {
    return {"StopSubscription":{"id": id}};
  },
  createNode: function (node: Node) {
    return {"CreateNode":{"id": this.createRequestId(), "node": node}};
  },
  updateNode: function (node: Node) {
    return {"UpdateNode":{"id": this.createRequestId(), "node": node}};
  },
  deleteNode: function (nodeId: string) {
    return {"DeleteNode":{"id": this.createRequestId(), "nodeId": nodeId}};
  },
  createEdge: function (edge: Edge) {
    return {"CreateEdge":{"id": this.createRequestId(), "edge": edge}};
  },
  updateEdge: function (edge: Edge) {
    return {"UpdateEdge":{"id": this.createRequestId(), "edge": edge}};
  },
  deleteEdge: function (edgeId: string) {
    return {"DeleteEdge":{"id": this.createRequestId(), "edgeId": edgeId}};
  },
  createGraph: function (nodes: Node[], edges: Edge[]) {
    return {"CreateGraph":{"id": this.createRequestId(), "nodes": nodes, "edges": edges}};
  },
  runScript: function (nodeId: string) {
    return {"RunScript":{"id": this.createRequestId(), "nodeId": nodeId}};
  },
  createTemporalGraph: function (nodes: Node[], edges: Edge[]) {
    return {"CreateTemporalGraph":{"id": this.createRequestId(), "nodes": nodes, "edges": edges}};
  },
}
