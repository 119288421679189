import {
  Node,
  Edge,
  isNodeUpdated, isNodeDeleted, isEdgeUpdated, isEdgeDeleted, Update
} from "./protocol";
import {LayoutEdgeLabelDeprecated, LayoutEdgeLabel, LayoutNodeData, LayoutNodeType} from "../../gatehouse-domain/data";
import {towerActions} from "./action";
import {generateIdentity} from "../../components/graph/cursors/generators";

const towerGraphInitialState = {
  version: 0,
  nodes: {} as {[key: string]: Node},
  edges: {} as {[key: string]: Edge},
  nodeOutgoing: {} as {[key: string]: Edge[]},
  nodeIncoming: {} as {[key: string]: Edge[]},
  scriptDeclarations: '/* not loaded */' as string,

  connectionStatus: {type: 'disconnected'} as ConnectionStatus,
  cursor: {
    nodeId: undefined as string | undefined,
    identity: generateIdentity(),
  },
};


export interface ConnectionConnecting {
  type: 'connecting'
  description: string
}

export interface ConnectionConnected {
  type: 'connected'
}

export interface ConnectionDisconnected {
  type: 'disconnected'
}

export type ConnectionStatus = ConnectionConnecting | ConnectionDisconnected | ConnectionConnected;



export interface TowerGraph {
  version: number;
  nodes: {[key: string]: Node};
  edges: {[key: string]: Edge};
}

function calculateEdgeIndex(edges: Edge[]): {nodeOutgoing: {[key: string]: Edge[]}, nodeIncoming: {[key: string]: Edge[]}} {
  const nodeOutgoing = {} as {[key: string]: Edge[]};
  const nodeIncoming = {} as {[key: string]: Edge[]};
  edges.forEach((edge: Edge) => {
    if (!nodeOutgoing[edge.source]) {
      nodeOutgoing[edge.source] = [edge];
    } else {
      nodeOutgoing[edge.source].push(edge);
    }
    if (!nodeIncoming[edge.target]) {
      nodeIncoming[edge.target] = [edge];
    } else {
      nodeIncoming[edge.target].push(edge);
    }
  })
  return {nodeOutgoing, nodeIncoming};
}

export function findLayoutNode(nodeId: string, state: typeof towerGraphInitialState): Node<LayoutNodeData> | undefined {
  for (let edge of (state.nodeOutgoing[nodeId] || [])) {
    if (edge.label === LayoutEdgeLabel) {
      let layoutNode = state.nodes[edge.target];
      if (layoutNode && layoutNode.type === LayoutNodeType) {
        return layoutNode;
      }
    }
  }

  for (let edge of (state.nodeIncoming[nodeId] || [])) {
    if (edge.label === LayoutEdgeLabelDeprecated) {
      let layoutNode = state.nodes[edge.source];
      if (layoutNode && layoutNode.type === LayoutNodeType) {
        return layoutNode;
      }
    }
  }
  return undefined;
}

export default function towerGraphReducer(state = towerGraphInitialState, action: any) {
  if (towerActions.setScriptDeclarations.match(action)) {
    return {
      ...state,
      scriptDeclarations: action.payload
    };
  } else if (towerActions.setSnapshot.match(action)) {
    const snapshot = action.payload;
    return {
      ...state,
      version: snapshot.version,
      nodes: snapshot.graph.nodes.reduce((acc: {[key: string]: Node}, node: Node) => {
        acc[node.id] = node;
        return acc;
      }, {}),
      edges: snapshot.graph.edges.reduce((acc: {[key: string]: Edge}, edge: Edge) => {
        acc[edge.id] = edge;
        return acc;
      }, {}),
      ...calculateEdgeIndex(snapshot.graph.edges)
    }
  } else if (towerActions.snapshotUpdate.match(action)) {
    const {version, updates} = action.payload;
    let nodes = {...state.nodes};
    let edges = {...state.edges};
    let topologyChanged = false;
    updates.forEach((update: Update) => {
      if (isNodeUpdated(update)) {
        nodes[update.NodeUpdated.node.id] = update.NodeUpdated.node;
      } else if (isNodeDeleted(update)) {
        topologyChanged = true;
        delete nodes[update.NodeDeleted.id];
      } else if (isEdgeUpdated(update)) {
        const oldEdge = edges[update.EdgeUpdated.edge.id];
        if (!oldEdge || oldEdge.source !== update.EdgeUpdated.edge.source || oldEdge.target !== update.EdgeUpdated.edge.target) {
          topologyChanged = true;
        }
        edges[update.EdgeUpdated.edge.id] = update.EdgeUpdated.edge;
      } else if (isEdgeDeleted(update)) {
        topologyChanged = true;
        delete edges[update.EdgeDeleted.id];
      }
    });
    if (topologyChanged) {
      return {
        ...state,
        version,
        nodes,
        edges,
        ...calculateEdgeIndex(Object.values(edges))
      }
    } else {
      return {...state, version, nodes, edges};
    }
  } else if (towerActions.connectionStatus.match(action)) {
    return {
      ...state,
      connectionStatus: action.payload,
    }
  } else if (towerActions.setCursorNodeId.match(action)) {
    return {
      ...state,
      cursor: {
        ...state.cursor,
        nodeId: action.payload,
      }
    }
  }

  return state;
}