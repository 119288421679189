import {Markdown} from "../Markdown";
import './MarkdownNode.css';
import {CustomNodeProps} from "../../customNodeProps";
import {TNodeData} from "../../../../store/tower/transform";

export interface MarkdownData {
  content: string;
}

export function MarkdownNode({data: {node: {data: {content}}}}: CustomNodeProps<TNodeData<MarkdownData>>) {

  const trimmedContent = String(content).trim();

  return (
    <>
      <div className={'markdown-node-container nowheel'}>
        <Markdown content={trimmedContent} />
      </div>
    </>
  );
}