import {Dimensions, XYPosition, Node, Edge} from "reactflow";
import {NodeDimensionChange, NodePositionChange} from "@reactflow/core/dist/esm/types/changes";
import {rfStateActions} from "./actions";

interface DraggingNodeState {
  nodeId: String;
  dimensions?: Dimensions;
  position?: XYPosition;
}

const reactflowInitialState = {
  draggingNode: undefined as (DraggingNodeState | undefined),
  selectedNode: undefined as (string | undefined),
  selectedEdge: undefined as (string | undefined),

  nodes: [] as Node[],
  edges: [] as Edge[],
}

export default function reactflowReducer(state = reactflowInitialState, action: any) {
  if (rfStateActions.nodeSelection.match(action)) {
    return {
      ...state, selectedNode: action.payload.selected ? action.payload.id : undefined,
    }
  } else if (rfStateActions.edgeSelection.match(action)) {
    return {
      ...state, selectedEdge: action.payload.selected ? action.payload.id : undefined,
    }
  } else if (rfStateActions.draggingNode.match(action)) {
    switch (action.payload.type) {
      case 'position': {
        const change = action.payload as NodePositionChange;
        return {...state,
          draggingNode: change.dragging ? {nodeId: change.id, position: change.position} : undefined,
        }
      }
      case 'dimensions': {
        const change = action.payload as NodeDimensionChange;
        return {...state,
          draggingNode: change.resizing ? {nodeId: change.id, dimensions: change.dimensions} : undefined,
        }
      }
      default:
        return state;
    }
  } else if (rfStateActions.setGraph.match(action)) {
    return {
      ...state,
      nodes: action.payload.nodes,
      edges: action.payload.edges,
    }
  } else {
    console.warn('unhandled action in reactflowReducer', action)
    return state;
  }
}
