import {CustomNodeProps} from "../customNodeProps";
import './node.css';
import {TNodeData} from "../../../store/tower/transform";

export interface TextNodeData {
  content?: string;
}

const NO_DATA = "No data";

export function TextNode({data: { node: { data: {content}}}}: CustomNodeProps<TNodeData<TextNodeData>>) {
  const contentToDisplay = content ?? NO_DATA;
  const contentLines = contentToDisplay.split("\n")

  return (
    <>
      <div className={'text-node-container nowheel'}>
        <div>{contentLines.map(line => (<div>{line}</div>))}</div>
      </div>
    </>
  );
}