import {NodeProps} from "reactflow";
import "./styles.css";
import {useAppSelector} from "../../../../store/store";
import {Column, TableInstance, useTable} from 'react-table'
import {shallowEqual} from "react-redux";
import {useMemo} from "react";
import {TNodeData} from "../../../../store/tower/transform";

function detectColumns(data: any[]): Column<any>[] {
  if (!Array.isArray(data)) {
    return [];
  }

  const keyStats =
    data
      .reduce((acc, row) => {
        Object.keys(row).forEach(key => {
          const valuesSet: any[] = acc[key] || [];
          if (valuesSet.findIndex(value => shallowEqual(value, row[key])) < 0) {
            valuesSet.push(row[key]);
          }
          acc[key] = valuesSet;
        });
        return acc;
      }, {})

  if (!keyStats) {
    return [];
  }

  // key with most values will be first column
  const columns =
    Object.keys(keyStats)
      .sort((a, b) => keyStats[b].length - keyStats[a].length)
      .map<Column<any>>(key => {
        return {
          Header: key,
          accessor: (row: any) => {
            const value = row[key] || ''
            // non string values will be rendered as json
            if (typeof value !== 'string') {
              return JSON.stringify(value);
            } else {
              return value;
            }
          },
        }
      })

  return columns;
}

function renderTableInstance(tableInstance: TableInstance<any>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return  <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
    <thead>
    {headerGroups.map(headerGroup => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
          <th
            {...column.getHeaderProps()}
            style={{
              borderBottom: 'solid 3px red',
              background: 'aliceblue',
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            {column.render('Header')}
          </th>
        ))}
      </tr>
    ))}
    </thead>
    <tbody {...getTableBodyProps()}>
    {rows.map(row => {
      prepareRow(row)
      return (
        <tr {...row.getRowProps()} key={row.index}>
          {row.cells.map(cell => {
            return (
              <td
                {...cell.getCellProps()}
                key={row.index.toString() + cell.column.id}
                style={{
                  padding: '10px',
                  border: 'solid 1px gray',
                  background: '#fffaf3',
                }}
              >
                {cell.render('Cell')}
              </td>
            )
          })}
        </tr>
      )
    })}
    </tbody>
  </table>
}

export function GenericNode({
                              id,
                              type,
                              data: {node: {data}},
                            }: Pick<NodeProps<TNodeData>, 'data' | 'id' | 'type'>) {

  // use type from props when this PR is accepted: https://github.com/wbkd/react-flow/pull/2860
  const nodeType = useAppSelector(state => state.graph.nodes[id]?.type || type);

  const columns = useMemo(() => detectColumns(data), [data])
  const tableInstance = useTable({columns, data})

  if (columns.length > 0) {
    return <div className={'table-node'}>
      {renderTableInstance(tableInstance)}
    </div>
  } else {

    return <div className={'generic-node'}>
      <div className={"type"}>
        {nodeType}
      </div>
      <div className={"content"}>
        {JSON.stringify(data)}
      </div>
    </div>
  }
}