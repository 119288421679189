import {Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {
  Graph,
  isSnapshot,
  isSnapshotUpdate,
  isSuccess,
  Response, Snapshot, SnapshotUpdate,
  TowerProtocol
} from "./protocol";
import {towerActions} from "./action";
import {CursorNodeData, CursorNodeType} from "../../gatehouse-domain/data";
import {AppDispatch, RootState} from "../store";
import {reactflowActions} from "../reactflow/actions";

export const towerMiddleware: Middleware = (store: MiddlewareAPI<AppDispatch, RootState>) => {
  let webSocket: WebSocket | null = null;
  let towerUrl: string | undefined = undefined;
  let snapshotStreamId: string | undefined = undefined;
  const responsePromises: { [id: string]: { resolve: (result: any) => void, reject: (error: string) => void } } = {};

  function webSocketUrl() {
    if (!towerUrl) {
      return undefined;
    }
    const withWS = towerUrl.replace(/^https/, "wss").replace(/^http/, "ws");
    return withWS.replace(/\/+$/, '') + '/graph/sync';
  }

  function scriptDeclarationsUrl() {
    if (!towerUrl) {
      return undefined;
    }
    return towerUrl.replace(/\/+$/, '') + '/tower/declarations';
  }

  function sendRequest(id: string, request: any) {
    return new Promise((resolve, reject) => {
      responsePromises[id] = {resolve, reject};
      webSocket!.send(JSON.stringify(request));
    });
  }

  return next => action => {
    if (towerActions.disconnect.match(action) && webSocket) {
      store.dispatch(towerActions.connectionStatus({type: 'disconnected'}))
      webSocket.close()
      webSocket = null
      return;
    }

    if (towerActions.connectionClosed.match(action)) {
      const closeEvent = action.payload;
      if (!closeEvent.wasClean) {
        store.dispatch(towerActions.connectionStatus({
          type: 'connecting',
          description: 'connection closed, reconnecting'
        }));
        store.dispatch(towerActions.connect(towerUrl!));
      } else {
        console.error('ws closed', closeEvent)
      }
    }

    if (towerActions.connect.match(action)) {
      if (webSocket) {
        webSocket.close()
        store.dispatch(towerActions.connectionStatus({
          type: 'connecting',
          description: 'connection closed, reconnecting'
        }));
      } else {
        store.dispatch(towerActions.connectionStatus({type: 'connecting', description: '...'}));
      }
      towerUrl = action.payload;
      webSocket = new WebSocket(webSocketUrl()!)

      store.dispatch(towerActions.getScriptDeclarations());

      webSocket.onopen = () => {
        store.dispatch(towerActions.connectionStatus({type: 'connected'}));
        const request = TowerProtocol.getSnapshot(true);
        snapshotStreamId = request.GetSnapshot.id;
        webSocket!.send(JSON.stringify(request));

        const cursorNodeData: CursorNodeData = {
          name: store.getState().graph.cursor.identity.name,
          color: store.getState().graph.cursor.identity.color,
        }
        const cursorRequest = TowerProtocol.createTemporalGraph(
          [{
            id: "",
            type: CursorNodeType,
            data: cursorNodeData,
          }], []
        );
        sendRequest(cursorRequest.CreateTemporalGraph.id, cursorRequest)
          .then(res => res as Graph)
          .then(graph => {
            store.dispatch(towerActions.setCursorNodeId(graph.nodes[0].id));
          })
      }

      webSocket.onclose = (closeEvent) => {
        store.dispatch(towerActions.connectionClosed({
          code: closeEvent.code, reason: closeEvent.reason, wasClean: closeEvent.wasClean
        }));
      }

      webSocket.onmessage = (event) => {
        const response = JSON.parse(event.data) as Response;
        if (response.id === snapshotStreamId) {
          if (isSuccess(response)) {
            console.log("success", response)
            if (isSnapshot(response.result)) {
              const snapshot = (response.result as { Snapshot: Snapshot }).Snapshot;
              store.dispatch(towerActions.setSnapshot(snapshot));
            } else if (isSnapshotUpdate(response.result)) {
              const update = (response.result as { SnapshotUpdate: SnapshotUpdate }).SnapshotUpdate;
              store.dispatch(towerActions.snapshotUpdate(update));
            }
          } else {
            console.error('error', response, response.error);
          }
        } else {
          const responsePromise = responsePromises[response.id];
          if (responsePromise) {
            delete responsePromises[response.id];
            const {resolve, reject} = responsePromise;
            if (isSuccess(response)) {
              resolve(response.result);
            } else {
              reject(response.error);
            }
          } else {
            console.error("received response for unknown request", response);
          }
        }
      }
      return;
    }

    if (towerActions.getScriptDeclarations.match(action)) {
      const url = scriptDeclarationsUrl();
      if (url) {
        fetch(url)
          .then(response => response.text())
          .then(declarations => store.dispatch(towerActions.setScriptDeclarations(declarations)));
      }
      return;
    }

    if (webSocket) {
      // TODO use promise from sendRequest to report errors to the user
      // maybe have a callback from the action creator that is called with the result

      if (towerActions.createGraph.match(action)) {
        const {nodes, edges} = action.payload;
        const request = TowerProtocol.createGraph(nodes, edges);
        sendRequest(request.CreateGraph.id, request);
        return;
      } else if (towerActions.createNode.match(action)) {
        const request = TowerProtocol.createNode(action.payload);
        sendRequest(request.CreateNode.id, request);
        return;
      } else if (towerActions.updateNode.match(action)) {
        const request = TowerProtocol.updateNode(action.payload);
        sendRequest(request.UpdateNode.id, request);
        return;
      } else if (towerActions.deleteNode.match(action)) {
        const request = TowerProtocol.deleteNode(action.payload);
        sendRequest(request.DeleteNode.id, request);
        return;
      } else if (towerActions.createEdge.match(action)) {
        const request = TowerProtocol.createEdge(action.payload);
        sendRequest(request.CreateEdge.id, request);
        return;
      } else if (towerActions.updateEdge.match(action)) {
        const request = TowerProtocol.updateEdge(action.payload);
        sendRequest(request.UpdateEdge.id, request);
        return;
      } else if (towerActions.deleteEdge.match(action)) {
        const request = TowerProtocol.deleteEdge(action.payload);
        sendRequest(request.DeleteEdge.id, request);
        return;
      } else if (towerActions.runScript.match(action)) {
        const request = TowerProtocol.runScript(action.payload);
        sendRequest(request.RunScript.id, request)
          .then(
            result => console.log("script result", result),
            error => alert(error)
          )
        return;
      }
    }

    if (reactflowActions.mouseMoved.match(action)) {
      const cursorNodeId = store.getState().graph.cursor.nodeId;
      if (!cursorNodeId) {
        return;
      }
      const cursorNode = store.getState().graph.nodes[cursorNodeId];
      if (!cursorNode) {
        return;
      }
      const {position} = action.payload;
      const request = TowerProtocol.updateNode({
        ...cursorNode,
        data: {
          ...cursorNode.data,
          position,
        }
      });
      sendRequest(request.UpdateNode.id, request);
      return;
    }

    next(action);
  }
}