
import Editor from "@monaco-editor/react";

interface ScriptEditorProps {
  value: string;
  language?: string;
  libSource?: string;
  onChange: (newValue: string) => void;
}

export function ScriptEditor({language, value, libSource, onChange}: ScriptEditorProps) {
  return <Editor
            height="100%"
            defaultLanguage={language || 'javascript'}
            defaultValue={value}
            onChange={(newValue) => {
              if (newValue) {
                onChange(newValue)
              }
            }}
            beforeMount={(monaco) => {
              if (libSource) {
                monaco.languages.typescript.javascriptDefaults.addExtraLib(libSource, 'ts:script-context-lib.d.ts');
              }
            }}
          />
}
